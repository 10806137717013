import configs from "../utils/configs";
import apiUtil from "../utils/api.utils";

/**
 * Get all incident report categories for a tenant.
 * @param {string} tenantId - The ID of the tenant.
 * @return {Promise<object>} - The response data or error.
 */
export const getIncidentReportCategories = async (tenantId) => {
  try {
    const url = `${configs.residentService}/api/v1.0/tenants/${tenantId}/incident-report-categories`;
    const response = await apiUtil.client.get(url);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

/**
 * Create a new incident report category.
 * @param {string} tenantId - The ID of the tenant.
 * @param {object} categoryData - The data for the new category.
 * @return {Promise<object>} - The response data or error.
 */
export const createIncidentReportCategory = async (tenantId, categoryData) => {
  try {
    const url = `${configs.residentService}/api/v1.0/tenants/${tenantId}/incident-report-categories`;
    const response = await apiUtil.client.post(url, categoryData);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

/**
 * Update an existing incident report category.
 * @param {string} tenantId - The ID of the tenant.
 * @param {string} categoryId - The ID of the category to update.
 * @param {object} categoryData - The updated data for the category.
 * @return {Promise<object>} - The response data or error.
 */
export const updateIncidentReportCategory = async (tenantId, categoryId, categoryData) => {
  try {
    const url = `${configs.residentService}/api/v1.0/tenants/${tenantId}/incident-report-categories/${categoryId}`;
    const response = await apiUtil.client.put(url, categoryData);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

/**
 * Delete an existing incident report category.
 * @param {string} tenantId - The ID of the tenant.
 * @param {string} categoryId - The ID of the category to delete.
 * @return {Promise<object>} - The response data or error.
 */
export const deleteIncidentReportCategory = async (tenantId, categoryId) => {
  try {
    const url = `${configs.residentService}/api/v1.0/tenants/${tenantId}/incident-report-categories/${categoryId}`;
    const response = await apiUtil.client.delete(url);
    return response?.data;
  } catch (error) {
    return { error: true, message: error };
  }
};
