import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

export const EditCategoryModal = ({open, category, onUpdate, onClose}) => {
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if (open && category) {
      setName(category.name);
      setDescription(category.description);
      setError(false);
    }
  }, [open, category])

  const handleUpdate = () => {
    if (name.trim() === '') {
      setError(true);
      return;
    }
    setError(false);
    onUpdate({name, description});
  }

  const isFormValid = () => {
    return name?.trim()?.length > 2;
  };

  return (
    <Dialog open={open}
            onClose={onClose}
            fullWidth
            maxWidth={"md"}
            aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit Category</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Category Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={error}
          helperText={error ? "Category Name is required" : ""}
        />
        <TextField
          margin="dense"
          id="description"
          label="Category Description"
          type="text"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button variant={"contained"}
                onClick={handleUpdate}
                startIcon={<AddIcon/>}
                color="primary"
                disabled={!isFormValid()}>Update</Button>
      </DialogActions>
    </Dialog>
  )
}
