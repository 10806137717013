import React from 'react';
import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {ListAlt} from "@material-ui/icons";
import {Link} from 'react-router-dom';
import {PageTitle} from "../../components/utils";
import useHasRole from "../../hooks/user.role.hook";

export const Administration = () => {
  const {checkPermission} = useHasRole();
  const canViewIncidentReportAdmin = checkPermission('INCIDENT_REPORT_ADMIN_READ');

  return (
    <div>
      <PageTitle title={"Administration"} />
      <List>
        <ListItem button divider component={Link} to={"/administration/careplans"}>
          <ListItemIcon>
            <ListAlt />
          </ListItemIcon>
          <ListItemText primary={"Careplan Statuses"} />
        </ListItem>
        {canViewIncidentReportAdmin &&
        <ListItem button divider component={Link} to={"/administration/incident-report"}>
          <ListItemIcon>
            <ListAlt/>
          </ListItemIcon>
          <ListItemText primary={"Incident Reports"}/>
        </ListItem>
        }
      </List>
    </div>
  )
}
