import React, {useContext, useEffect, useState} from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  FormControlLabel,
  Switch,
  ButtonGroup,
} from '@material-ui/core';
import {Delete as DeleteIcon, Add as AddIcon, ArrowUpward, ArrowDownward} from '@material-ui/icons';
import {PageTitle} from "../../../components/utils";
import {CreateCategoryModal} from "./Create";
import {getTenantFeatures, updateTenantFeatures} from "../../../services/tenant.service";
import {UserContext} from "../../../context/usercontext";
import {
  createIncidentReportCategory,
  deleteIncidentReportCategory,
  getIncidentReportCategories, updateIncidentReportCategory
} from "../../../services/incident.report.service";
import {RetryMessage} from "../../../components/notification/retry.message";
import useGlobalNotification from "../../../hooks/notification.hook";
import {getStripedStyle} from "../../../components/utils/table.utils";
import {EditCategoryModal} from "./EditCategory";
import EditIcon from "@material-ui/icons/Edit";

const IncidentReportPage = () => {
  const {selectedTenant} = useContext(UserContext);
  const [tenantFeatures, setTenantFeatures] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [editModalOpen, setEditOpen] = useState(false);
  const {addNotification} = useGlobalNotification();

  useEffect(() => {
    getFeatures().then(() => {});
    // eslint-disable-next-line
  }, [selectedTenant?._id]);

  useEffect(() => {
    if (tenantFeatures?.incidentReport?.customCategories) {
      getData().then(() => {});
    }
    // eslint-disable-next-line
  }, [tenantFeatures?.incidentReport?.customCategories]);

  useEffect(() => {
    if (categories.length) {
      setCategories(categories.sort((a, b) => a.sort - b.sort));
    }
  }, [categories]);

  const getFeatures = async () => {
    const response = await getTenantFeatures(selectedTenant?._id);
    if (!response?.error) {
      setTenantFeatures(response);
    }
  };

  const getData = async () => {
    setLoading(true);
    setError(false);
    const response = await getIncidentReportCategories(selectedTenant?._id);
    if (!response?.error) {
      setCategories(response);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const handleAddCategory = async (category) => {
    category.sort = categories.length + 1;
    const newCategory = await createIncidentReportCategory(selectedTenant?._id, category);
    setOpen(false);
    setCategories(prevCategories =>
      [...prevCategories, newCategory].sort((a, b) => a.sort - b.sort)
    );
  };

  const handleDeleteCategory = async (category) => {
    await deleteIncidentReportCategory(selectedTenant?._id, category?._id);
    const newCategories = categories.filter((item) => item._id !== category._id);
    setCategories(newCategories.sort((a, b) => a.sort - b.sort));
  };

  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setEditOpen(true);
  };

  const handleUpdateCategory = async (category) => {
    const newCategory = await updateIncidentReportCategory(selectedTenant?._id, selectedCategory?._id, category);
    setEditOpen(false);
    const newCategories = categories.map((item) => {
      if (item._id === newCategory._id) {
        return newCategory;
      }
      return item;
    });
    setCategories(newCategories.sort((a, b) => a.sort - b.sort));
  }

  const handleMoveUp = async (index) => {
    if (index <= 0) return;
    const newCategories = [...categories];
    const temp = newCategories[index - 1];

    // Swap elements in the array
    newCategories[index - 1] = newCategories[index];
    newCategories[index] = temp;

    // Check if sort exists; if not, initialize them
    if (newCategories[index - 1].sort === undefined) {
      newCategories[index - 1].sort = index - 1;
    }
    if (newCategories[index].sort === undefined) {
      newCategories[index].sort = index;
    }

    // Swap their sort properties
    const tempSort = newCategories[index - 1].sort;
    newCategories[index - 1].sort = newCategories[index].sort;
    newCategories[index].sort = tempSort;

    try {
      // Update the sort in backend for both categories
      const update1 = await updateIncidentReportCategory(selectedTenant?._id, newCategories[index - 1]._id, newCategories[index - 1]);
      const update2 = await updateIncidentReportCategory(selectedTenant?._id, newCategories[index]._id, newCategories[index]);

      if (update1.error || update2.error) {
        throw new Error('API update failed');
      }

      // Reflect changes in the local state
      setCategories(newCategories.sort((a, b) => a.sort - b.sort));
    } catch (error) {
      console.error('Failed to update category sort:', error);
      // Optionally handle the error state or notify the user
    }
  };

  const handleMoveDown = async (index) => {
    if (index >= categories.length - 1) return;
    const newCategories = [...categories];
    const temp = newCategories[index + 1];

    // Swap elements in the array
    newCategories[index + 1] = newCategories[index];
    newCategories[index] = temp;

    // Check if sorts exist; if not, initialize them
    if (newCategories[index + 1].sort === undefined) {
      newCategories[index + 1].sort = index + 1;
    }
    if (newCategories[index].sort === undefined) {
      newCategories[index].sort = index;
    }

    // Swap their sort properties
    const tempSort = newCategories[index + 1].sort;
    newCategories[index + 1].sort = newCategories[index].sort;
    newCategories[index].sort = tempSort;


    try {
      // Update the sort in backend for both categories
      const update1 = await updateIncidentReportCategory(selectedTenant?._id, newCategories[index + 1]._id, newCategories[index + 1]);
      const update2 = await updateIncidentReportCategory(selectedTenant?._id, newCategories[index]._id, newCategories[index]);

      if (update1.error || update2.error) {
        throw new Error('API update failed');
      }

      // Reflect changes in the local state
      setCategories(newCategories.sort((a, b) => a.sort - b.sort));
    } catch (error) {
      console.error('Failed to update category sort:', error);
      // Optionally handle the error state or notify the user
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onChange = async (e) => {
    const obj = {...tenantFeatures}
    if (obj?.incidentReport) {
      obj.incidentReport.customCategories = e.target.checked;
    } else {
      obj.incidentReport = {
        customCategories: e.target.checked
      }
    }
    const response = await updateTenantFeatures(selectedTenant?._id, obj);
    if (!response?.error) {
      setTenantFeatures(response);
      addNotification("Tenant features updated.", "success");
    } else {
      addNotification("Unable to update tenant feature", "error");
    }
  }

  return (
    <div>
      <PageTitle title={"Incident Report"}/>
      <div>
        <FormControlLabel
          style={{
            alignSelf: 'flex-start',
            margin: '1rem 0 0 0',
          }}
          name={"custom-categories"}
          control={
            <Switch
              name={"custom-categories"}
              onChange={onChange}
              color="primary"
              checked={tenantFeatures?.incidentReport?.customCategories || ''}
            />
          }
          label={"Incident Report Categories (Select from the dropdown)"}
          labelPlacement="start"
        />
      </div>
      {tenantFeatures?.incidentReport?.customCategories &&
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={4}>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon/>}
                    onClick={handleOpen}
                  >
                    Add Category
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={200} />
                <TableCell>Category</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories?.map((category, index) => (
                <TableRow key={index} style={{ ...getStripedStyle(index)}}>
                  <TableCell>
                    <ButtonGroup variant="contained" size="small">
                      <IconButton onClick={() => handleMoveUp(index)}>
                        <ArrowUpward />
                      </IconButton>
                      <IconButton onClick={() => handleMoveDown(index)}>
                        <ArrowDownward />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteCategory(category)}>
                        <DeleteIcon />
                      </IconButton>
                      <IconButton onClick={() => handleEditCategory(category)}>
                        <EditIcon />
                      </IconButton>
                    </ButtonGroup>
                  </TableCell>
                  <TableCell>
                    {category?.name}
                  </TableCell>
                  <TableCell>
                    {category?.description}
                  </TableCell>
                </TableRow>
              ))}

              {error && !loading &&
                <TableRow>
                  <TableCell colSpan={3}>
                    <RetryMessage message={"Unable to load careplan statues, please try again."}
                                  onRetry={getData}
                                  severity={"error"} />
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      }
      <CreateCategoryModal open={open}
                           onAdd={handleAddCategory}
                           onClose={() => setOpen()}
      />
      <EditCategoryModal open={editModalOpen}
                         category={selectedCategory}
                         onUpdate={handleUpdateCategory}
                         onClose={() => setEditOpen(false)}
      />
    </div>
  );
};

export default IncidentReportPage;
